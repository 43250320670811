import React, { lazy, Suspense } from 'react'
import { render, hydrate } from 'react-dom'
import { register } from './serviceWorker'

import 'normalize.css'
import 'bulma/css/bulma.css'
import 'rc-slider/assets/index.css'

import './index.css'

const App = lazy(() => import('./app'))

const rootElement = document.getElementById('root')
if (rootElement.hasChildNodes()) {
  hydrate(
    <Suspense fallback={<div>Loading...</div>}>
      <App />
    </Suspense>, rootElement)
} else {
  render(
    <Suspense fallback={<div>Loading...</div>}>
      <App />
    </Suspense>, rootElement)
}

if (navigator.userAgent !== 'ReactSnap') register()
